.settings-page {
  display: grid;
}

.settings-container {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  /* border: solid 1px rgb(244, 244, 244); */
  /* border-radius: 5px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  /* padding-top: 20px; */
  padding-bottom: 30px;
  /* box-shadow: 0px 0px 1px 1px rgb(244, 244, 244); */
}
.settings-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.settings-items-billing {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.settings-left-panel {
  display: flex;
  flex-direction: column;
}
.settings-left-panel-developer {
  width: 100%;
  text-align: right;
}

.developerKey {
  background-color: #fff !important;
  border: 1px solid #cfd8dc !important;
  border-radius: 8px;
  padding: 12px 16px px 16px;
}
.settings-right-panel {
  display: flex;
  flex-direction: row;
}

.single-menu-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10px; */
}

/* .settings-text {
  font-size: x-large;
  font-weight: lighter;
} */

.language-selection-text {
  margin-top: 15px;
  margin-right: 10px;
}

.remaining-credit-text {
  margin-top: -21px;
  margin-left: 343px;
}

.additional-minutes-text {
  margin-top: -28px;
  margin-left: 345px;
}

.logout-btn {
  padding: 5px;
  color: #25da3c;
  background: white;
  border: solid 1px #25da3c;
  border-radius: 3px;
  height: 40px;
}

.generate-Api {
  display: block;
  margin-top: 10px;
}
.generate-Api:disabled {
  cursor: not-allowed;
  border: solid 1px grey;
  color: grey;
}

.logout-btn:hover {
  cursor: pointer;
  background: rgb(250, 250, 250);
}

.additionalMinutesNote {
  font-size: small;
  margin-left: 345px;
}

.minutes-text {
  font-weight: bold;
}

.manage-billing-btn {
  color: #fff;
  background-color: #25da3c;
  border-color: #25da3c;
  border-radius: 3px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  margin-top: 13px;
  padding: 10px;
}
.id-password {
  display: flex;
  flex-direction: column;
}

.change-password-btn {
  background: none;
  color: #25da3c;
  border: none;
  text-align: end;
  margin-right: 3px;
}
.id-heading {
  margin-top: 5px;
  margin-right: 10px;
}
.cancel-btn-dialog {
  background-color: #252627;
  color: #ffff;
  border: none;
  height: 30px;
  border-radius: 3px;
  border: solid 1px black;
}
.save-btn-dialog {
  color: #25da3c;
  background: white;
  border: solid 1px #25da3c;
  border-radius: 3px;
  height: 30px;
  margin-right: 17px;
}
.btn-disable {
  background: none;
  color: gray;
  border-radius: 3px;
  border: solid 1px gray;
  height: 30px;
  margin-right: 17px;
}
.wrong-credentials {
  color: red;
  position: relative;
  top: 12px;
}
.pass-match {
  color: red;
}
.auth-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.current-password {
  position: relative;
  top: 28px;
  display: flex;
}
.change-password-label {
  font-weight: 600;
  font-size: 20px;
}
.new-pass {
  display: flex;
}

.settings-container .setting-select > div {
  min-width: 100px !important;
}
