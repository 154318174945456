/* Global stylings for a healtier application
---------------------------------------------------------------- */
html {
  text-size-adjust: 100%; /* Fix mobiles that does not respect meta viewport for font-scaling */
  -webkit-tap-highlight-color: transparent; /* Prevent ugly dark overlay when pressing links in iOS */
}
/* Ligatures are nice, but some browsers also puts ligatures in input fields.
This can break search and other submittions, as for instance "ft" is converted to a single character  */
input,
textarea,
[contenteditable] {
  font-feature-settings: 'liga' 0;
  text-rendering: optimizeSpeed;
}
/* SVGs should by default inherit color from text for better accessibility.
The "fill" attribute however overwrites this CSS rule, making this default definition safe.
Also, add workaround for FF bug: https://bugzilla.mozilla.org/show_bug.cgi?id=265894 */
svg {
  fill: currentColor;
}
svg > use > svg {
  fill: inherit;
}

/* Remove default margin, border and setup color inheritance for saner styling */
body,
img,
svg,
a,
iframe {
  margin: 0;
  border: 0;
  color: inherit;
}

/* Speeds up click event on older phones as links and buttons now
are expected to be a clicked, and does not wait for pinch */
button,
a {
  touch-action: manipulation;
}

/* Utilities
---------------------------------------------------------------- */
.lstn-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lstn-unset {
  appearance: none; /* Neded for <input> */
  background: none;
  border: 0 solid;
  border-radius: 0; /* Needed for <button> */
  border-spacing: 0; /* Needed for <table> */
  border-collapse: collapse; /* Needed for <table> */
  box-sizing: border-box;
  color: inherit; /* Needed for <a> */
  font: inherit;
  height: auto;
  list-style: none; /* Needed for <ul> / <ol> */
  margin: 0;
  max-height: none; /* Needed for <fieldset> */
  max-width: none; /* Needed for <fieldset> */
  min-height: auto; /* Needed for <fieldset> */
  min-width: auto; /* Needed for <fieldset> */
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  text-decoration: inherit;
  width: auto;
}
.lstn-unset::-ms-expand {
  /* Needed to hide IE arrow in <select> */
  display: none;
}

/* Grid
---------------------------------------------------------------- */
.lstn-grid > * {
  box-sizing: border-box;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
}

.lstn-grid {
  /* This declaration must be after .lstn-grid > * */
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
}

.lstn-grid--equalize > * {
  display: flex;
}
.lstn-grid--equalize > * > * {
  width: 100%;
}
.lstn-grid--nowrap > * {
  white-space: normal;
}
.lstn-grid--nowrap {
  /* This declaration must be after .lstn-grid--nowrap > * */
  white-space: nowrap;
  flex-wrap: nowrap;
}

/* Button
---------------------------------------------------------------- */
.lstn-button::-moz-focus-inner {
  border: 0;
  padding: 0;
} /* Reset Firefox */
.lstn-button {
  -webkit-appearance: none; /* Reset iOS */
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  margin: 3px 1px;
  padding: 0 19px;
  border: 0 solid; /* Set solid style for psuedo inheritance */
  border-radius: 99px;
  height: 41px;
  min-width: 41px; /* Ensure minimum touch target */
  font: inherit;
  font-size: 14px;
  line-height: 41px;
  text-align: center;
  text-decoration: none;
  background: transparent;
  color: inherit;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}
.lstn-button::after {
  content: '';
  display: block; /* Avoid half pixel overflow:hidden bug in Chrome */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4; /* lstn-color-shade-4 is 78% luminosity, which equals 22% darkness */
  border: inherit; /* Enable changing border without addressing pseudo element */
  border-radius: inherit; /* Enable changing border-radius without addressing pseudo element */
  border-width: 1px;
  transition: border 0.2s; /* Transition border on pesudo only prevents flash of unstyled border */
}
.lstn-button:active {
  transform: scale(0.95);
}
.lstn-button:hover,
.lstn-button:focus {
  outline: 0;
  border-color: transparent;
  box-shadow: 0 0 2px 1px #00b9f2;
}
.lstn-button:disabled,
.lstn-button.is-disabled {
  cursor: default;
  pointer-events: none;
  box-shadow: none;
  opacity: 0.6;
}
.lstn-button.is-busy::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-top-color: transparent;
  border-radius: 50%;
  margin-right: 5px;
  animation: lstn-spin 1s infinite linear;
}
.lstn-button svg {
  vertical-align: middle;
} /* Align with text */
.lstn-button--o {
  width: 41px;
  height: 41px;
  padding: 10px;
}
.lstn-button--o svg {
  display: block;
  width: 100%;
  height: 100%;
}
.lstn-spin {
  animation: lstn-spin 1s infinite linear;
}
@keyframes lstn-spin {
  to {
    transform: rotate(360deg);
  }
}

/* Forms
---------------------------------------------------------------- */
.lstn-input {
  appearance: none;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  background: #fff no-repeat;
  margin: 0;
  border: 0;
  max-width: 100%; /* prevent overflowing parent container */
  outline: 0;
  color: #141517;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5; /* Makes input[type="file"] equal height as other inputs */
  height: 2.6em; /* Makes all textual inputs same height. This and line-height is needed for that. */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15); /* IE11 doesn't render border on checkbox and radio */
  border-radius: 4px;
  padding: 0.5em 0.7em;
  transition: box-shadow 0.2s;
}

.lstn-input:focus {
  box-shadow: 0 0 2px 1px #00b9f2;
}
.lstn-input:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.lstn-input[readonly] {
  opacity: 0.8;
  cursor: default !important;
}

.lstn-input::-ms-expand, /* reset IE select arrow */
.lstn-input::-ms-check {
  /* reset IE checkbox check */
  display: none;
}
.lstn-input::-ms-value {
  /* reset IE input[type="file"] text style  */
  background: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  border: 0;
}

.lstn-input[type='file'] {
  cursor: pointer;
}

.lstn-input[type='radio'] {
  border-radius: 100%;
}
.lstn-input[type='radio'],
.lstn-input[type='checkbox'] {
  width: 1.3em;
  height: 1.3em;
  vertical-align: middle;
  cursor: pointer;
  padding: 0;
}

.lstn-input[type='radio']:checked {
  background-image: radial-gradient(
    at center center,
    transparent 25%,
    currentcolor 28%
  );
}

/* draw custom check icon as a background-image using
currentColor and transparency to allow for customizing
the foreground and background color on the element */
.lstn-input[type='checkbox']:checked {
  background-position-x: 35%, 0%, 100%, 100%, 100%, 100%, 100%;
  background-image:
    linear-gradient(0deg, transparent 38%, currentColor 38%),
    /* Used to fix Samsung Internet issue #86  */
      linear-gradient(45deg, transparent 53%, currentColor 54%),
    linear-gradient(135deg, currentColor 37%, transparent 38%),
    linear-gradient(-135deg, currentColor 23%, transparent 23%),
    linear-gradient(135deg, currentColor 33%, transparent 33%),
    linear-gradient(45deg, currentcolor 28%, transparent 27%),
    linear-gradient(-45deg, currentcolor 42%, transparent 41%);
  background-size:
    5% 100%,
    36% 100%,
    64% 100%,
    100%,
    100%,
    100%,
    100%;
}

.lstn-input[type='checkbox']:indeterminate {
  background-position-x: 0, 100%, 0;
  background-position-y: 0;
  background-image: linear-gradient(currentColor 0%, currentColor 100%),
    linear-gradient(currentColor 0%, currentColor 100%),
    linear-gradient(
      currentColor 43%,
      transparent 44%,
      transparent 58%,
      currentColor 59%
    );
  background-size:
    20% 100%,
    20% 100%,
    100% auto;
}

textarea.lstn-input {
  height: auto;
  overflow: auto; /* Consistenly show scrollbar */
  resize: vertical; /* Prevent horizontally resizable textarea */
  vertical-align: top;
  line-height: inherit;
}

select.lstn-input[multiple] {
  height: auto;
  vertical-align: top;
}

select.lstn-input:not([multiple]) {
  background-image: linear-gradient(
      45deg,
      transparent 46%,
      currentcolor 47%,
      currentcolor 49%,
      transparent 51%
    ),
    linear-gradient(
      -45deg,
      transparent 46%,
      currentcolor 47%,
      currentcolor 49%,
      transparent 51%
    );
  background-size:
    0.36em 100%,
    0.36em 100%;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1.35em), calc(100% - 1em);
  background-position-y: 0;
  padding-right: 2.5em;
  vertical-align: top;
}

.lstn-switch {
  appearance: none;
  vertical-align: middle;
  background: transparent;
  margin: 0;
  border: 0;
  outline: 0;
  background: radial-gradient(
    circle at 25%,
    transparent 25%,
    currentColor 27.5%,
    currentColor
  );
  border-radius: 50px;
  cursor: pointer;
  color: inherit;
  font: inherit; /* Scale according to parent font size */
  width: 3em;
  height: 1.5em;
  opacity: 0.5;
  transition: background-position 0.2s;
}

.lstn-switch::-ms-check {
  display: none;
} /* reset IE checkbox check */
.lstn-switch:disabled {
  opacity: 0.2;
  cursor: not-allowed !important;
}
.lstn-switch:focus {
  box-shadow: 0 0 2px 1px #00b9f2;
}
.lstn-switch:active:hover {
  background-position-x: 0.3em;
}
.lstn-switch:checked {
  opacity: 1;
  background-position-x: 1.5em;
}
.lstn-switch:checked:active:hover {
  background-position-x: 1.2em;
}

/* Pagination
---------------------------------------------------------------- */
.lstn-pagination {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-size: 0;
}
.lstn-pagination::before,
.lstn-pagination::after {
  content: '';
  display: inline-block;
  width: 50%;
  border-top: 1px solid;
  opacity: 0.22;
}
.lstn-pagination::before {
  margin-left: -50%;
}
.lstn-pagination::after {
  margin-right: -50%;
}
.lstn-pagination > .lstn-button:only-child {
  min-width: 200px;
}
.lstn-pagination > .lstn-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}
/* Undo half-pixel Chrome-fix */
.lstn-pagination > .lstn-button:not(:last-child)::after {
  right: 0;
}
.lstn-pagination > .lstn-button + .lstn-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-style: none;
  margin-left: 0;
}

/* Sizes
---------------------------------------------------------------- */
.lstn-xs-100 {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}
.lstn-xs-1of12 {
  width: 8.33333%;
}
.lstn-xs-2of12 {
  width: 16.6666%;
}
.lstn-xs-3of12 {
  width: 25%;
}
.lstn-xs-4of12 {
  width: 33.3333%;
}
.lstn-xs-5of12 {
  width: 41.6666%;
}
.lstn-xs-6of12 {
  width: 50%;
}
.lstn-xs-7of12 {
  width: 58.3333%;
}
.lstn-xs-8of12 {
  width: 66.6666%;
}
.lstn-xs-9of12 {
  width: 75%;
}
.lstn-xs-10of12 {
  width: 83.3333%;
}
.lstn-xs-11of12 {
  width: 91.6666%;
}
.lstn-xs-12of12 {
  width: 100%;
}
@media (min-width: 40rem) {
  .lstn-sm-1of12 {
    width: 8.33333%;
  }
  .lstn-sm-2of12 {
    width: 16.6666%;
  }
  .lstn-sm-3of12 {
    width: 25%;
  }
  .lstn-sm-4of12 {
    width: 33.3333%;
  }
  .lstn-sm-5of12 {
    width: 41.6666%;
  }
  .lstn-sm-6of12 {
    width: 50%;
  }
  .lstn-sm-7of12 {
    width: 58.3333%;
  }
  .lstn-sm-8of12 {
    width: 66.6666%;
  }
  .lstn-sm-9of12 {
    width: 75%;
  }
  .lstn-sm-10of12 {
    width: 83.3333%;
  }
  .lstn-sm-11of12 {
    width: 91.6666%;
  }
  .lstn-sm-12of12 {
    width: 100%;
  }
}
@media (min-width: 45rem) {
  .lstn-md-1of12 {
    width: 8.33333%;
  }
  .lstn-md-2of12 {
    width: 16.6666%;
  }
  .lstn-md-3of12 {
    width: 25%;
  }
  .lstn-md-4of12 {
    width: 33.3333%;
  }
  .lstn-md-5of12 {
    width: 41.6666%;
  }
  .lstn-md-6of12 {
    width: 50%;
  }
  .lstn-md-7of12 {
    width: 58.3333%;
  }
  .lstn-md-8of12 {
    width: 66.6666%;
  }
  .lstn-md-9of12 {
    width: 75%;
  }
  .lstn-md-10of12 {
    width: 83.3333%;
  }
  .lstn-md-11of12 {
    width: 91.6666%;
  }
  .lstn-md-12of12 {
    width: 100%;
  }
}
@media (min-width: 62rem) {
  .lstn-lg-1of12 {
    width: 8.33333%;
  }
  .lstn-lg-2of12 {
    width: 16.6666%;
  }
  .lstn-lg-3of12 {
    width: 25%;
  }
  .lstn-lg-4of12 {
    width: 33.3333%;
  }
  .lstn-lg-5of12 {
    width: 41.6666%;
  }
  .lstn-lg-6of12 {
    width: 50%;
  }
  .lstn-lg-7of12 {
    width: 58.3333%;
  }
  .lstn-lg-8of12 {
    width: 66.6666%;
  }
  .lstn-lg-9of12 {
    width: 75%;
  }
  .lstn-lg-10of12 {
    width: 83.3333%;
  }
  .lstn-lg-11of12 {
    width: 91.6666%;
  }
  .lstn-lg-12of12 {
    width: 100%;
  }
}
@media (min-width: 90rem) {
  .lstn-xl-1of12 {
    width: 8.33333%;
  }
  .lstn-xl-2of12 {
    width: 16.6666%;
  }
  .lstn-xl-3of12 {
    width: 25%;
  }
  .lstn-xl-4of12 {
    width: 33.3333%;
  }
  .lstn-xl-5of12 {
    width: 41.6666%;
  }
  .lstn-xl-6of12 {
    width: 50%;
  }
  .lstn-xl-7of12 {
    width: 58.3333%;
  }
  .lstn-xl-8of12 {
    width: 66.6666%;
  }
  .lstn-xl-9of12 {
    width: 75%;
  }
  .lstn-xl-10of12 {
    width: 83.3333%;
  }
  .lstn-xl-11of12 {
    width: 91.6666%;
  }
  .lstn-xl-12of12 {
    width: 100%;
  }
}

/* Aspects
---------------------------------------------------------------- */
.lstn-aspect {
  position: relative;
}
.lstn-aspect > * {
  box-sizing: border-box;
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
}
.lstn-aspect::after {
  content: '';
  display: block;
  padding-top: 56.25%;
}
.lstn-aspect--9x16::after {
  padding-top: 177.78%;
}
.lstn-aspect--4x3::after {
  padding-top: 75%;
}
.lstn-aspect--1x1::after {
  padding-top: 100%;
}

/* Colors
---------------------------------------------------------------- */
.lstn-color-base {
  color: #26292a;
  background-color: #fff;
}
.lstn-color-spot {
  color: #26292a;
  background-color: #00b9f2;
}
.lstn-color-invert {
  color: #fff;
  background-color: #141517;
}
.lstn-color-shade-1 {
  color: #26292a;
  background-color: #f3f3f3;
}
.lstn-color-shade-2 {
  color: #26292a;
  background-color: #e9e9e9;
}
.lstn-color-shade-3 {
  color: #26292a;
  background-color: #e0e0e0;
}
.lstn-color-shade-4 {
  color: #26292a;
  background-color: #c8c8c8;
}
.lstn-fade {
  opacity: 0.4;
}

/* Accessibility
---------------------------------------------------------------- */
.lstn-sr,
.lstn-sr-focus:not(:focus) {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
}
