@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
  font-size: 16px;
}
/*
  We inherit box-sizing: border-box; from our <html> selector
  Apparently this is a bit better than applying box-sizing: border-box; directly to the * selector
*/
*,
*:before,
*:after {
  box-sizing: inherit;
}

.app-container {
  display: grid;
  grid-template-rows: auto 1fr;
}

header {
  display: grid;
  padding: 0.5em;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  grid-auto-flow: column;
  align-items: center;
}

main#create {
  margin: 20px;
  display: grid;
  grid-template-columns: minmax(auto, 600px);
  justify-content: center;
  align-content: start;
}

fieldset {
  border: none;
}

/* Navigation */

.header-green-lstn {
  display: grid;
  padding: 0.5em;
  grid-template-columns: auto 1fr;
  grid-gap: 0px;
  grid-auto-flow: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.logo a:visited,
.logo a:active,
.logo a:link,
.logo a:hover {
  color: #25da3c;
  text-decoration: none;
  font-weight: 700;
}

header div.user a {
  color: white;
  text-decoration: none;
}

.login a {
  color: white;
  text-decoration: none;
}

/* Speakers */

.speaker {
  border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  width: 1.6em;
  cursor: pointer;
}

.speaker-1 {
  background-color: rgb(29, 158, 218);
}
.speaker-2 {
  background-color: rgb(230, 92, 33);
}
.speaker-3 {
  background-color: rgb(165, 102, 163);
}
.speaker-4 {
  background-color: rgb(4, 76, 151);
}
.speaker-5 {
  background-color: rgb(70, 168, 61);
}

/* Heat map */

.confidence-0 {
  border-bottom: 2px solid #000000;
}
.confidence-1 {
  border-bottom: 2px solid #080000;
}
.confidence-2 {
  border-bottom: 2px solid #0f0000;
}
.confidence-3 {
  border-bottom: 2px solid #170000;
}
.confidence-4 {
  border-bottom: 2px solid #1f0000;
}
.confidence-5 {
  border-bottom: 2px solid #260000;
}
.confidence-6 {
  border-bottom: 2px solid #2e0000;
}
.confidence-7 {
  border-bottom: 2px solid #360000;
}
.confidence-8 {
  border-bottom: 2px solid #3d0000;
}
.confidence-9 {
  border-bottom: 2px solid #450000;
}
.confidence-10 {
  border-bottom: 2px solid #4d0000;
}
.confidence-11 {
  border-bottom: 2px solid #540000;
}
.confidence-12 {
  border-bottom: 2px solid #5c0000;
}
.confidence-13 {
  border-bottom: 2px solid #640000;
}
.confidence-14 {
  border-bottom: 2px solid #6b0000;
}
.confidence-15 {
  border-bottom: 2px solid #730000;
}
.confidence-16 {
  border-bottom: 2px solid #7b0000;
}
.confidence-17 {
  border-bottom: 2px solid #820000;
}
.confidence-18 {
  border-bottom: 2px solid #8a0000;
}
.confidence-19 {
  border-bottom: 2px solid #920000;
}
.confidence-20 {
  border-bottom: 2px solid #990000;
}
.confidence-21 {
  border-bottom: 2px solid #a10000;
}
.confidence-22 {
  border-bottom: 2px solid #a90000;
}
.confidence-23 {
  border-bottom: 2px solid #b00000;
}
.confidence-24 {
  border-bottom: 2px solid #b80000;
}
.confidence-25 {
  border-bottom: 2px solid #c00000;
}
.confidence-26 {
  border-bottom: 2px solid #c70000;
}
.confidence-27 {
  border-bottom: 2px solid #cf0000;
}
.confidence-28 {
  border-bottom: 2px solid #d70000;
}
.confidence-29 {
  border-bottom: 2px solid #de0000;
}
.confidence-30 {
  border-bottom: 2px solid #e60000;
}
.confidence-31 {
  border-bottom: 2px solid #e70700;
}
.confidence-32 {
  border-bottom: 2px solid #e80e00;
}
.confidence-33 {
  border-bottom: 2px solid #e91500;
}
.confidence-34 {
  border-bottom: 2px solid #e91c00;
}
.confidence-35 {
  border-bottom: 2px solid #ea2300;
}
.confidence-36 {
  border-bottom: 2px solid #eb2a00;
}
.confidence-37 {
  border-bottom: 2px solid #ec3100;
}
.confidence-38 {
  border-bottom: 2px solid #ed3800;
}
.confidence-39 {
  border-bottom: 2px solid #ee3f00;
}
.confidence-40 {
  border-bottom: 2px solid #ee4600;
}
.confidence-41 {
  border-bottom: 2px solid #ef4d00;
}
.confidence-42 {
  border-bottom: 2px solid #f05400;
}
.confidence-43 {
  border-bottom: 2px solid #f15b00;
}
.confidence-44 {
  border-bottom: 2px solid #f26200;
}
.confidence-45 {
  border-bottom: 2px solid #f36900;
}
.confidence-46 {
  border-bottom: 2px solid #f37000;
}
.confidence-47 {
  border-bottom: 2px solid #f47700;
}
.confidence-48 {
  border-bottom: 2px solid #f57e00;
}
.confidence-49 {
  border-bottom: 2px solid #f68500;
}
.confidence-50 {
  border-bottom: 2px solid #f78c00;
}
.confidence-51 {
  border-bottom: 2px solid #f89300;
}
.confidence-52 {
  border-bottom: 2px solid #f89a00;
}
.confidence-53 {
  border-bottom: 2px solid #f9a100;
}
.confidence-54 {
  border-bottom: 2px solid #faa800;
}
.confidence-55 {
  border-bottom: 2px solid #fbaf00;
}
.confidence-56 {
  border-bottom: 2px solid #fcb600;
}
.confidence-57 {
  border-bottom: 2px solid #fdbd00;
}
.confidence-58 {
  border-bottom: 2px solid #fdc400;
}
.confidence-59 {
  border-bottom: 2px solid #fecb00;
}
.confidence-60 {
  border-bottom: 2px solid #ffd200;
}
.confidence-61 {
  border-bottom: 2px solid #ffd306;
}
.confidence-62 {
  border-bottom: 2px solid #ffd40d;
}
.confidence-63 {
  border-bottom: 2px solid #ffd513;
}
.confidence-64 {
  border-bottom: 2px solid #ffd71a;
}
.confidence-65 {
  border-bottom: 2px solid #ffd820;
}
.confidence-66 {
  border-bottom: 2px solid #ffd926;
}
.confidence-67 {
  border-bottom: 2px solid #ffda2d;
}
.confidence-68 {
  border-bottom: 2px solid #ffdb33;
}
.confidence-69 {
  border-bottom: 2px solid #ffdc39;
}
.confidence-70 {
  border-bottom: 2px solid #ffdd40;
}
.confidence-71 {
  border-bottom: 2px solid #ffde46;
}
.confidence-72 {
  border-bottom: 2px solid #ffe04d;
}
.confidence-73 {
  border-bottom: 2px solid #ffe153;
}
.confidence-74 {
  border-bottom: 2px solid #ffe259;
}
.confidence-75 {
  border-bottom: 2px solid #ffe360;
}
.confidence-76 {
  border-bottom: 2px solid #ffe466;
}
.confidence-77 {
  border-bottom: 2px solid #ffe56c;
}
.confidence-78 {
  border-bottom: 2px solid #ffe673;
}
.confidence-79 {
  border-bottom: 2px solid #ffe779;
}
.confidence-80 {
  border-bottom: 2px solid #ffe980;
}
.confidence-81 {
  border-bottom: 2px solid #ffea86;
}
.confidence-82 {
  border-bottom: 2px solid #ffeb8c;
}
.confidence-83 {
  border-bottom: 2px solid #ffec93;
}
.confidence-84 {
  border-bottom: 2px solid #ffed99;
}
.confidence-85 {
  border-bottom: 2px solid #ffee9f;
}
.confidence-86 {
  border-bottom: 2px solid #ffefa6;
}
.confidence-87 {
  border-bottom: 2px solid #fff0ac;
}
.confidence-88 {
  border-bottom: 2px solid #fff2b3;
}
.confidence-89 {
  border-bottom: 2px solid #fff3b9;
}
.confidence-90 {
  border-bottom: 2px solid #fff4bf;
}
.confidence-91 {
  border-bottom: 2px solid #fff5c6;
}
.confidence-92 {
  border-bottom: 2px solid #fff6cc;
}
.confidence-93 {
  border-bottom: 2px solid #fff7d2;
}

.word-highlight-yellow {
  background-color: rgba(255, 235, 59, 0.6);
}
.word-highlight-blue {
  background-color: rgba(103, 223, 255, 0.6);
}
.word-highlight-violet {
  background-color: rgba(225, 150, 255, 0.6);
}
.word-highlight-pink {
  background-color: rgba(255, 101, 159, 0.6);
}
.word-highlight-orange {
  background-color: rgba(255, 152, 0, 0.6);
}

.played-words {
  color: #202020;
}

.playing-word {
  color: #263238;
  /* font-weight: bold; */
}

.paragraph .word {
  position: relative;
  font-family: Roboto;
  font-size: 16px;
  padding-right: 5px;
}

.paragraph .word span {
  position: relative;
}

.paragraph .word span.typewriter {
  position: absolute;
  left: 0;
  top: 0;
  height: 19px;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation:
    typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

.paragraph .word span.typewriter-end {
  position: absolute;
  right: 0;
  top: 0;
  height: 19px;
  overflow: hidden;
  border-right: 0.15em solid black;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation:
    typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}
.verifyParagraph {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.verifyParagraph:hover {
  visibility: visible;
  opacity: 1;
}
.metaTimecode:hover + .verifyParagraph {
  visibility: visible;
  opacity: 1;
}
.verifyParagraph:checked {
  visibility: visible;
  opacity: 1;
}
.verifyParagraph:active {
  visibility: visible;
  opacity: 1;
}

/* Transcripts */

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

main#transcripts {
  display: grid;
  grid-template-columns: 460px 1fr;
}

.org-shadow-l {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.org-color-shade {
  background-color: #ffffff;
}

/* Progress */

.progress {
  padding: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 20px;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      0.25em 0 0 rgba(0, 0, 0, 0),
      0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow:
      0.25em 0 0 rgba(0, 0, 0, 0),
      0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow:
      0.25em 0 0 black,
      0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow:
      0.25em 0 0 black,
      0.5em 0 0 black;
  }
}

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

/* Transcript */

.marker {
  background: rgb(37, 218, 60, 0.5);
}

.startTime {
  color: rgb(119, 131, 143);
  font-size: 12px;
}

.paragraphs {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  padding: 16px;
  padding-bottom: 100px;
  font-family: 'Roboto Mono', monospace;
}

.paragraph s {
  color: lightgray;
}

.startTime:first-of-type {
  cursor: pointer;
}

.paragraphs p {
  font-size: 24px;
  margin-top: 0.5rem;
  border-radius: 4px;
  background: white;
  line-height: 1.8;
}

.paragraph {
  cursor: pointer;
}

.paragraph *::selection {
  background-color: rgb(37, 218, 60, 0.3);
}

.paragraph *::-moz-selection {
  background-color: rgb(37, 218, 60, 0.3);
}

.paragraph .word:hover {
  color: #04c61d;
}

/* Player */

/* #player {
  margin-left: 2px;
  width: calc(100% - 460px);
  height: 64px;
  background: white;
  display: flex;
  color: #77838f;
  padding: 0 8px;
  bottom: 0;
  left: 460px;
  position: fixed;
  box-shadow: 3px -2px 3px rgba(0,0,0,.05);
}

#player button {
  background: transparent;
  font-size: 2rem;
  border: none;
  width: 64px;
  color: #25da3c;
}

#player .timer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}

#player .timer-background {
  width: 100%;
  height: 9px;
  border-radius: 2px;
  background: rgba(0,0,0,.1);
  overflow: hidden;
} */

/* #player .timer-current {
  background: #25da3c; /* fallback for old browsers */
/* background: -webkit-linear-gradient(to right, #80d78c, #25da3c); /* Chrome 10-25, Safari 5.1-6 */
/* background: linear-gradient(to right, #80d78c, #25da3c); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/* height: 100%; */
/* transition: 1s ease; */
/* } */

/* .currentTime,
.duration {
  padding: 0 4px;
  display: grid;
  align-content: center;
}

#player .volume {
  display: flex;
  align-items: center;
  padding: 0 8px;
}
 
input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  background: #25da3c;
  border-radius: 1.3px;
  border: 0.2px solid #77838f;
}
input[type="range"]::-webkit-slider-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  border: 1px solid #77838f;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #25da3c;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  background: #25da3c;
  border-radius: 1.3px;
  border: 0.2px solid #77838f;
}
input[type="range"]::-moz-range-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  border: 1px solid #77838f;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #25da3c;
  border: 0.2px solid #77838f;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
}
input[type="range"]::-ms-fill-upper {
  background: #25da3c;
  border: 0.2px solid #77838f;
  border-radius: 2.6px;
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
}
input[type="range"]::-ms-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; 
  border: 1px solid #77838f;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #25da3c;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #25da3c;
} */

/* Editor */
.paragraph .word .typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation:
    typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: black;
  }
  50% {
    border-color: transparent;
  }
}

/* General */

.lstn-green {
  color: #25da3c;
}

.lstn-grey {
  color: #77838f;
}

/* LSTN BUTTONS */

.lstn-btn {
  background-color: #2196f3;
  display: flex;
  justify-content: center;
}

.lstn-btn {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
  margin-left: 20px;
  display: flex;
  justify-content: center;
}

.lstn-btn:hover {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.lstn-btn.focus {
  /* box-shadow: 0 0 0 .2rem rgba(4, 198, 29, .5) */
}

.lstn-btn.disabled,
.lstn-btn:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.lstn-btn:not(:disabled):not(.disabled).active,
.lstn-btn:not(:disabled):not(.disabled):active,
.show > .lstn-btn.dropdown-toggle {
  color: #fff;
  background-color: #2196f3;
  /* border-color: #25da3c */
}

.lstn-btn:not(:disabled):not(.disabled).active:focus,
.lstn-btn:not(:disabled):not(.disabled):active:focus,
.show > .lstn-btn.dropdown-toggle:focus {
  /* box-shadow: 0 0 0 .2rem rgba(4, 198, 29, .5) */
}

/* LSTN OTHER */

.estimated-transcription-time {
  padding: 10px;
  padding-top: 50px;
}

.form-creazione {
  margin-top: 30px;
}

.form-creazione-optional {
  margin-top: 20px;
}

.weight-300 {
  font-weight: 300;
}

/* Landing Auth */

.landing-auth {
  display: grid;
  align-items: center;
  justify-items: center;
}

.card-auth {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 100%;
  display: grid;
  position: relative;
  justify-content: center;
}

/* .card-auth:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
} */

.card-auth-content {
  padding: 2px 16px;
}

.container-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.item-auth {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  flex-basis: auto;
  text-align: center;
  font-size: 1em;
  padding: 3em 8em 6em 8em;
}

/* .item-auth:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
} */

.logo-auth {
  color: #25da3c;
  text-decoration: none;
  font-weight: 700;
  font-size: 3em;
}

.payment-container {
  width: 100%;
  height: 100%;
  text-align: center;
}

.payment-container iframe {
  width: 100%;
  height: 100%;
}

.header-container {
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 13px;
  margin-bottom: 13px;
}

.header-container-trial {
  display: flex;
  align-items: center;
  color: #000000;
}

/* .header-container .header-display-name {
  margin-left: 20px;
  margin-right: 20px;
} */

.header-upgrade-button {
  background: transparent;
  border: none;
  outline: none;
  padding: 10px;
  color: #25da3c;
  font-size: 15px;
  cursor: pointer;
}

.header-nav-btn {
  color: #000000;
  /* margin-left: 20px; */
  width: 108px;
  margin-top: 8px;
  padding: 3px;
}

.lstn-logo-header {
  background-color: transparent;
  border: none;
  outline: none;
  color: #25da3c;
  font-weight: 700;
}

.lstn-logo-header:hover {
  cursor: pointer;
}

.spash-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Transcript Description --------------------------- */
.transcript-description {
  padding: 10px;
}

/* .transcript-description textarea::placeholder {
  color: #BFC6CC;
} */

/* Transcript Action --------------------------- */
.transcript-action-btn {
  border: 1px solid rgba(37, 38, 39, 0.5);
  padding: 0.3em 0em;
}

.transcript-action-btn img {
  top: 2px;
  position: relative;
}

.transcript-action-delete-btn {
  padding: 0.3em 0em;
}

.transcript-action-delete-btn svg {
  font-size: 26px;
}

/* getGist messenger styling */

div#gist-app div div.gist-messenger-bubble-iframe iframe {
  bottom: 75px !important;
}

/* Else */

.lstn-transcription-text {
  font-size: 16px;
  letter-spacing: 0.3px;
  color: rgb(119, 131, 143);
}

.gist-messenger-bubble-iframe iframe {
  z-index: 99999 !important;
}

/*  settings button*/
.settings-btn {
  background: none;
  border: none;
  margin-right: 5px;
}

.settings-btn:hover {
  cursor: pointer;
}

.red {
  color: red !important;
}

.black {
  color: #000 !important;
}

.countryCheckbox label {
  padding: 0 0px 0 0 !important;
  margin-right: 0.5rem;
  border-radius: 0 !important;
}

.drawer-status > span,
.drawer-status > div {
  position: relative !important;
  transform: translateY(0) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.drawer-status > div {
  margin-top: 2px !important;
}
.drawer-status .status_icon {
  display: none !important;
}

.custom-tooltip {
  left: auto !important;
  right: 0.8rem !important;
}
