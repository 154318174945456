.incident-details-container {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 0px;
  width: 100%;
  max-width: 1024px;
}

.inner-container {
  padding: 0px 40px;
}

.details-container {
  margin-top: 35px;
  padding: 35px 60px;
  border-width: 1px;
  border-color: '#E9ECEF';
}

@media (max-width: 768px) {
  .details-container {
    margin-top: 35px;
    padding: 35px 10px;
    border-width: 1px;
    border-color: '#E9ECEF';
  }
}

.incident-info {
  margin-top: 10px;
  margin-bottom: 20px;
}

.image-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.image-container {
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 5px;
}
