/* Base
---------------------------------------------------------------- */
body {
  margin: 0;
} /* Always reset <body> margin */

:root {
  --org-color-bright: #fff;
  --org-color-shade: rgba(196, 205, 213, 0.2);
  --org-color-dark: #252627;
  --org-color-focus: #00b9f2;
  --org-color-error: #e62421;
  --org-color-primary: #1769ff;
  --org-color-warning: #ffff42;
  --org-color-success: #3d8704;
  --org-color-unsaved: #fff5c6;

  --org-unit: 5px;
  --org-unit-s: calc(var(--org-unit) * 2);
  --org-unit-m: calc(var(--org-unit) * 4);
  --org-unit-l: calc(var(--org-unit) * 8);

  --org-text-s: 12px;
  --org-text-m: 14px;
  --org-text-l: 24px;
  --org-text-xl: 32px;
  --org-text-xxl: 48px;
}

.org-root {
  font:
    14px/1.5 'Roboto',
    sans-serif;
  color: var(--org-color-dark);
  background-color: var(--org-color-bright);
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}
.org-root * {
  outline: 0;
}
.org-root svg {
  vertical-align: middle;
  fill: currentColor;
}
/* .org-root .focus-visible { box-shadow: inset 0 0 0 2px var(--org-color-focus) } */

/* Unset all the things!
---------------------------------------------------------------- */
.org-unset::-ms-expand, /* Hide <select> arrow in IE */
.org-unset::-ms-check,  /* Hide checkbox in IE */
.org-btn input::-ms-check,
.org-label input::-ms-check,
.org-label input::-ms-expand {
  opacity: 0;
}

.org-unset,
.org-expand,
.org-switch,
.org-btn,
.org-btn input,
.org-label input,
.org-label legend,
.org-label select,
.org-label textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Neded for <input> */
  background: none;
  border: 0 solid;
  border-radius: 0; /* Needed for <button> */
  border-spacing: 0; /* Needed for <table> */
  border-collapse: collapse; /* Needed for <table> */
  box-sizing: border-box;
  color: inherit; /* Needed for <a> */
  font: inherit;
  height: auto;
  list-style: none; /* Needed for <ul> / <ol> */
  margin: 0;
  max-height: none; /* Needed for <fieldset> */
  max-width: none; /* Needed for <fieldset> */
  min-height: auto; /* Needed for <fieldset> */
  min-width: auto; /* Needed for <fieldset> */
  padding: 0;
  resize: none; /* Needed for <textarea> */
  text-align: inherit;
  text-decoration: none;
  vertical-align: middle; /* Needed for all inline-block elements */
  width: auto;
}

/* Forms
---------------------------------------------------------------- */
.org-btn,
.org-expand,
.org-label input,
.org-label select,
.org-label textarea {
  width: 100%;
  margin-top: var(--org-unit);
  margin-bottom: var(--org-unit-s);
  padding: 0.5em 0.8em;
  border-radius: 3px;
  font-weight: 400;
  font-size: var(
    --org-text-m
  ); /* TODO Needs to be 16px to not zoom on iOS devices */
  line-height: 1.5;
  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.1),
    inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background: var(--org-color-bright);
  transition: 0.2s;
}

.org-btn:disabled,
.org-btn[aria-disabled='true'],
.org-label input:disabled,
.org-label select:disabled,
.org-label textarea:disabled {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
}

.org-btn {
  width: auto;
  min-width: 2.5em;
  background: none;
  box-shadow: none;
  position: relative; /* Needed to position child inputs */
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.org-btn:hover {
  background: var(--org-color-shade);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.org-btn:active {
  transform: scale(0.9);
}
.org-btn--s {
  font-size: var(--org-text-s);
}
.org-btn--l {
  font-size: var(--org-text-l);
}
.org-btn--round {
  width: 70px;
  height: 70px;
  padding: 0;
  border: 0;
  border-radius: 100%;
}

.org-btn--primary {
  color: var(--org-color-bright);
  background: var(--org-color-primary);
  box-shadow: 0 1px 9px var(--org-color-primary);
}
.org-btn--primary:hover {
  background: #1253cb;
  box-shadow: 0 1px 9px var(--org-color-primary);
}

.org-btn input {
  border-radius: inherit;
  position: absolute;
  cursor: inherit;
  top: 0;
  left: 0;
  border: 0 solid var(--org-color-focus); /* Prepare border color for animation */
  width: 100%;
  height: 100%;
  transition: 0.2s;
}
.org-btn input:checked {
  background: linear-gradient(
      to top,
      var(--org-color-primary) 2px,
      transparent 2px
    ),
    var(--org-color-shade);
}

.org-label,
.org-label legend {
  font-weight: 500;
  font-size: var(--org-text-m);
}
.org-label small {
  font-weight: 300;
  font-size: var(--org-text-s);
  float: right;
}

.org-label input[type='file'] {
  cursor: pointer;
  text-indent: -7.2em;
} /* text-indent hides button in FireFox */
.org-label input[type='file']::-webkit-file-upload-button {
  border: 0;
  padding: 0;
  width: 0;
  font: inherit;
}
.org-label input[type='file']::-ms-browse {
  visibility: hidden;
} /* Hide browse files in IE */
/* .org-label input[type="range"] {} https://scottaohara.github.io/a11y_styled_form_controls/src/range-slider/ */
.org-label input[type='radio'] {
  width: auto;
}

.org-label select:not([multiple]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 13l3.5-4h-7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 30px 20px;
  padding-right: 30px;
}

.org-label input[type='checkbox'] {
  cursor: pointer;
  width: 5em;
  height: 2.5em;
  border-radius: 999px;
  box-shadow:
    inset -2.5em 0 0 2px,
    inset 0 0 0 2px;
  transition: 0.2s;
  opacity: 0.5;
}

.org-label input[type='checkbox']:hover {
  opacity: 0.7;
}
.org-label input[type='checkbox']:active:hover {
  box-shadow:
    inset -1.5em 0 0 2px,
    inset 0 0 0 2px;
}
.org-label input[type='checkbox'].org-switch--s {
  font-size: var(--org-text-s);
}
.org-label input[type='checkbox'].focus-visible {
  opacity: 1;
  border: 2px solid var(--org-color-focus);
}
.org-label input[type='checkbox']:disabled {
  opacity: 0.2;
}
.org-label input[type='checkbox']:checked:active:hover {
  box-shadow:
    inset 1.5em 0 0 2px,
    inset 0 0 0 2px;
}
.org-label input[type='checkbox']:checked:checked {
  opacity: 1;
  color: var(--org-color-primary);
  box-shadow:
    inset 2.5em 0 0 2px,
    inset 0 0 0 2px;
}

.org-root input:checked ~ .org-unchecked,
.org-root input:not(:checked) ~ .org-checked {
  display: none;
}

/* Expand
---------------------------------------------------------------- */
.org-expand {
  margin: 0;
  font-weight: 500;
  border-radius: 0;
  box-shadow: inset 0 -1px var(--org-color-shade);
}
.org-expand::before {
  content: '';
  display: inline-block;
  margin: 2px;
  width: 7px;
  height: 7px;
  box-shadow: inset 1px 1px; /* Inset makes circular animation position nicley */
  transform: rotate(135deg);
  transition: 0.3s;
}
.org-expand[aria-expanded='true']::before {
  transform: rotate(225deg);
}
.org-expand + * {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  transition: 0.3s;
}
.org-expand + [hidden] {
  visibility: hidden;
  height: 0;
}

/* Progress bars
---------------------------------------------------------------- */
.org-progress:indeterminate,
.org-progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  width: 100%;
  height: 5px;
  color: #326bff;
  background-color: #c5cdd5;
  position: relative;
  margin: 0 0 1.5em;
  background-size:
    5px 20px,
    100% 100%,
    100% 100%;
}

/* Progress background */
.org-progress[value]::-webkit-progress-bar,
.org-progress:indeterminate::-webkit-progress-bar {
  background-color: #c5cdd5;
}

/* Progress bar */
.org-progress[value]::-webkit-progress-value,
.org-progress:indeterminate::-webkit-progress-value {
  background-color: #326bff;
  transition: 0.2s;
}
.org-progress[value]::-moz-progress-bar, /* moz selector cannot reside next to webkit, it breaks the style */
.org-progress:indeterminate::-moz-progress-bar {
  background-color: #326bff;
  transition: 0.2s;
}

/* Percentage */
.org-progress[value]::after {
  content: attr(value) '%';
  position: absolute;
  right: 0;
  color: black;
  width: 2.5em;
  height: 2em;
}

/* Completed */
.org-progress[data-status='completed'],
.org-progress[data-status='completed']::-webkit-progress-value {
  background-color: #478700;
  color: #478700;
}
.org-progress[data-status='completed']::-moz-progress-bar {
  background-color: #478700;
}
.org-progress[data-status='completed']::after {
  content: '';
  background: url("data:image/svg+xml; utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' id='icon-checkmark' width='100%25' height='100%25' fill='%23478700'%3E%3Cpath d='M8.038,15.286,3.586,11,5,9.586l2.962,2.8,7.506-8.427,1.486,1.338Z'%3E%3C/path%3E%3C/svg%3E%0A")
    center no-repeat;
}

/* Partial error */
.org-progress[data-status='partial-error'],
.org-progress[data-status='partial-error']::-webkit-progress-value {
  background-image: -webkit-linear-gradient(left, #326bff, #e62421);
  color: #e62421;
}
.org-progress[data-status='partial-error']::-moz-progress-bar {
  background-image: -moz-linear-gradient(left, #326bff, #e62421);
  color: #e62421;
}
.org-progress[data-status='partial-error']::after {
  color: #e62421;
}

/* Error */
.org-progress[data-status='error'],
.org-progress[data-status='error']::-webkit-progress-value {
  background-color: #e62421;
  color: #e62421;
}
.org-progress[data-status='error']::-moz-progress-bar {
  background-color: #e62421;
  color: #e62421;
}
.org-progress[data-status='error']::after {
  color: #e62421;
}

/* Indeterminate progress bar */
.org-progress:indeterminate {
  overflow: hidden;
}
.org-progress:indeterminate::before,
.org-progress:indeterminate::after {
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  content: '';
  border: 5px solid #326bff;
  will-change: left, right;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
  width: auto;
  top: 0;
}
.org-progress:indeterminate::after {
  animation: indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1.15s;
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.org-progress:indeterminate::-moz-progress-bar {
  background: none;
  background-image: -moz-linear-gradient(
    135deg,
    transparent 10%,
    #326bff 20%,
    transparent 30%,
    #326bff 40%,
    transparent 50%,
    #326bff 60%,
    transparent 70%,
    #326bff 80%,
    transparent 90%,
    #326bff 100%
  );
}
/* Table
---------------------------------------------------------------- */
.org-table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.org-table th,
.org-table td {
  padding: var(--org-unit) 0;
  border-bottom: 1px solid var(--org-color-shade);
  text-align: left;
}

/* Typography
---------------------------------------------------------------- */
.org-text-xxl {
  margin: 0;
  font-weight: 300;
  font-size: var(--org-text-xxl);
  line-height: 1.2;
}
.org-text-xl {
  margin: 0;
  font-weight: 300;
  font-size: var(--org-text-xl);
  line-height: 1.2;
}
.org-text-l {
  margin: 0;
  font-weight: 300;
  font-size: var(--org-text-l);
  line-height: 1.2;
}
.org-text-m {
  margin: 0;
  font-weight: 500;
  font-size: var(--org-text-m);
}
.org-text-s {
  margin: 0;
  font-weight: 400;
  font-size: var(--org-text-s);
}

.org-text-left {
  text-align: left;
}
.org-text-right {
  text-align: right;
}
.org-text-center {
  text-align: center;
}
.org-text-nowrap {
  white-space: nowrap;
}

/* Bar
---------------------------------------------------------------- */
.org-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  margin-top: 50px;
  padding: 0;
  list-style: none;
}

.org-text-l {
  display: flex;
  flex-direction: row;
}

.org-bar a {
  text-decoration: none;
}

.org-bar > :first-child {
  flex: 1;
}

.org-bar > * {
  margin: var(--org-unit-s);
}

/* Grid (Gutter uses margin-left and margin-top to avoid scrollbar
---------------------------------------------------------------- */
.org-grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
} /* Resets for ul/ol */
.org-grid > * {
  box-sizing: border-box;
  flex-shrink: 0;
}
.org-grid--nowrap {
  flex-wrap: nowrap;
}
.org-grid--gutter {
  margin-left: calc(var(--org-unit-s) * -1);
  margin-top: calc(var(--org-unit-s) * -1);
}
.org-grid--gutter > * {
  margin-left: var(--org-unit-s);
  margin-top: var(--org-unit-s);
}
.org-grid--stretch > * {
  flex: 1;
}

/* Sizes
---------------------------------------------------------------- */
.org-1of12 {
  width: 8.33333%;
  flex-basis: 8.33333%;
}
.org-2of12 {
  width: 16.6666%;
  flex-basis: 16.6666%;
}
.org-3of12 {
  width: 25%;
  flex-basis: 25%;
}
.org-4of12 {
  width: 33.3333%;
  flex-basis: 33.3333%;
}
.org-5of12 {
  width: 41.6666%;
  flex-basis: 41.6666%;
}
.org-6of12 {
  width: 50%;
  flex-basis: 50%;
}
.org-7of12 {
  width: 58.3333%;
  flex-basis: 58.3333%;
}
.org-8of12 {
  width: 66.6666%;
  flex-basis: 66.6666%;
}
.org-9of12 {
  width: 75%;
  flex-basis: 75%;
}
.org-10of12 {
  width: 83.3333%;
  flex-basis: 83.3333%;
}
.org-11of12 {
  width: 91.6666%;
  flex-basis: 91.6666%;
}
.org-12of12 {
  width: 100%;
  flex-basis: 100%;
}

@media (min-width: 40rem) {
  .org-s-1of12 {
    width: 8.33333%;
    flex-basis: 8.33333%;
  }
  .org-s-2of12 {
    width: 16.6666%;
    flex-basis: 16.6666%;
  }
  .org-s-3of12 {
    width: 25%;
    flex-basis: 25%;
  }
  .org-s-4of12 {
    width: 33.3333%;
    flex-basis: 33.3333%;
  }
  .org-s-5of12 {
    width: 41.6666%;
    flex-basis: 41.6666%;
  }
  .org-s-6of12 {
    width: 50%;
    flex-basis: 50%;
  }
  .org-s-7of12 {
    width: 58.3333%;
    flex-basis: 58.3333%;
  }
  .org-s-8of12 {
    width: 66.6666%;
    flex-basis: 66.6666%;
  }
  .org-s-9of12 {
    width: 75%;
    flex-basis: 75%;
  }
  .org-s-10of12 {
    width: 83.3333%;
    flex-basis: 83.3333%;
  }
  .org-s-11of12 {
    width: 91.6666%;
    flex-basis: 91.6666%;
  }
  .org-s-12of12 {
    width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 45rem) {
  .org-m-1of12 {
    width: 8.33333%;
    flex-basis: 8.33333%;
  }
  .org-m-2of12 {
    width: 16.6666%;
    flex-basis: 16.6666%;
  }
  .org-m-3of12 {
    width: 25%;
    flex-basis: 25%;
  }
  .org-m-4of12 {
    width: 33.3333%;
    flex-basis: 33.3333%;
  }
  .org-m-5of12 {
    width: 41.6666%;
    flex-basis: 41.6666%;
  }
  .org-m-6of12 {
    width: 50%;
    flex-basis: 50%;
  }
  .org-m-7of12 {
    width: 58.3333%;
    flex-basis: 58.3333%;
  }
  .org-m-8of12 {
    width: 66.6666%;
    flex-basis: 66.6666%;
  }
  .org-m-9of12 {
    width: 75%;
    flex-basis: 75%;
  }
  .org-m-10of12 {
    width: 83.3333%;
    flex-basis: 83.3333%;
  }
  .org-m-11of12 {
    width: 91.6666%;
    flex-basis: 91.6666%;
  }
  .org-m-12of12 {
    width: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 62rem) {
  .org-l-1of12 {
    width: 8.33333%;
    flex-basis: 8.33333%;
  }
  .org-l-2of12 {
    width: 16.6666%;
    flex-basis: 16.6666%;
  }
  .org-l-3of12 {
    width: 25%;
    flex-basis: 25%;
  }
  .org-l-4of12 {
    width: 33.3333%;
    flex-basis: 33.3333%;
  }
  .org-l-5of12 {
    width: 41.6666%;
    flex-basis: 41.6666%;
  }
  .org-l-6of12 {
    width: 50%;
    flex-basis: 50%;
  }
  .org-l-7of12 {
    width: 58.3333%;
    flex-basis: 58.3333%;
  }
  .org-l-8of12 {
    width: 66.6666%;
    flex-basis: 66.6666%;
  }
  .org-l-9of12 {
    width: 75%;
    flex-basis: 75%;
  }
  .org-l-10of12 {
    width: 83.3333%;
    flex-basis: 83.3333%;
  }
  .org-l-11of12 {
    width: 91.6666%;
    flex-basis: 91.6666%;
  }
  .org-l-12of12 {
    width: 100%;
    flex-basis: 100%;
  }
}

/* Utilites
---------------------------------------------------------------- */
/* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.org-sr {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
}
.org-float-left {
  float: left;
}
.org-float-right {
  float: right;
}
.org-float-clear::after {
  content: '';
  display: table;
  clear: both;
}

[data-org-tooltip] {
  position: relative;
}
[data-org-tooltip]::after {
  content: attr(data-org-tooltip);
  white-space: nowrap;
  position: absolute;
  z-index: 999;
  left: 0;
  bottom: 100%;
  color: var(--org-color-bright);
  background: var(--org-color-dark);
  outline: var(--org-unit) solid transparent; /* Increase hover area */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  font-size: var(--org-text-s);
  line-height: var(--org-unit-s);
  padding: var(--org-unit);
  border-radius: 3px;
  transition: 0.2s;
}
[data-org-tooltip]:not(:hover)::after {
  transform: translateY(var(--org-unit));
  visibility: hidden;
  opacity: 0;
}

/* Padding
---------------------------------------------------------------- */
/* Padding None */
.org-pan {
  padding: 0;
}
.org-ptn {
  padding-top: 0;
}
.org-pln {
  padding-left: 0;
}
.org-prn {
  padding-right: 0;
}
.org-pbn {
  padding-bottom: 0;
}
.org-phn {
  padding-left: 0;
  padding-right: 0;
}
.org-pvn {
  padding-top: 0;
  padding-bottom: 0;
}

/* Padding Extra Small */
.org-paxs {
  padding: var(--org-unit);
}
.org-ptxs {
  padding-top: var(--org-unit);
}
.org-plxs {
  padding-left: var(--org-unit);
}
.org-prxs {
  padding-right: var(--org-unit);
}
.org-pbxs {
  padding-bottom: var(--org-unit);
}
.org-phxs {
  padding-left: var(--org-unit);
  padding-right: var(--org-unit);
}
.org-pvxs {
  padding-top: var(--org-unit);
  padding-bottom: var(--org-unit);
}

/* Padding Small */
.org-pas {
  padding: var(--org-unit-s);
}
.org-pts {
  padding-top: var(--org-unit-s);
}
.org-pls {
  padding-left: var(--org-unit-s);
}
.org-prs {
  padding-right: var(--org-unit-s);
}
.org-pbs {
  padding-bottom: var(--org-unit-s);
}
.org-phs {
  padding-left: var(--org-unit-s);
  padding-right: var(--org-unit-s);
}
.org-pvs {
  padding-top: var(--org-unit-s);
  padding-bottom: var(--org-unit-s);
}

/* Padding Medium */
.org-pam {
  padding: var(--org-unit-m);
}
.org-ptm {
  padding-top: var(--org-unit-m);
}
.org-plm {
  padding-left: var(--org-unit-m);
}
.org-prm {
  padding-right: var(--org-unit-m);
}
.org-pbm {
  padding-bottom: var(--org-unit-m);
}
.org-phm {
  padding-left: var(--org-unit-m);
  padding-right: var(--org-unit-m);
}
.org-pvm {
  padding-top: var(--org-unit-m);
  padding-bottom: var(--org-unit-m);
}

/* Padding Large */
.org-pal {
  padding: var(--org-unit-l);
}
.org-ptl {
  padding-top: var(--org-unit-l);
}
.org-pll {
  padding-left: var(--org-unit-l);
}
.org-prl {
  padding-right: var(--org-unit-l);
}
.org-pbl {
  padding-bottom: var(--org-unit-l);
}
.org-phl {
  padding-left: var(--org-unit-l);
  padding-right: var(--org-unit-l);
}
.org-pvl {
  padding-top: var(--org-unit-l);
  padding-bottom: var(--org-unit-l);
}

/* Margin
---------------------------------------------------------------- */
/* Margin none */
.org-man {
  margin: 0;
}
.org-mtn {
  margin-top: 0;
}
.org-mln {
  margin-left: 0;
}
.org-mrn {
  margin-right: 0;
}
.org-mbn {
  margin-bottom: 0;
}
.org-mhn {
  margin-left: 0;
  margin-right: 0;
}
.org-mvn {
  margin-top: 0;
  margin-bottom: 0;
}

/* Margin Extra Small */
.org-maxs {
  margin: var(--org-unit);
}
.org-mtxs {
  margin-top: var(--org-unit);
}
.org-mlxs {
  margin-left: var(--org-unit);
}
.org-mrxs {
  margin-right: var(--org-unit);
}
.org-mbxs {
  margin-bottom: var(--org-unit);
}
.org-mhxs {
  margin-left: var(--org-unit);
  margin-right: var(--org-unit);
}
.org-mvxs {
  margin-top: var(--org-unit);
  margin-bottom: var(--org-unit);
}

/* Margin Small */
.org-mas {
  margin: var(--org-unit-s);
}
.org-mts {
  margin-top: var(--org-unit-s);
}
.org-mls {
  margin-left: var(--org-unit-s);
}
.org-mrs {
  margin-right: var(--org-unit-s);
}
.org-mbs {
  margin-bottom: var(--org-unit-s);
}
.org-mhs {
  margin-left: var(--org-unit-s);
  margin-right: var(--org-unit-s);
}
.org-mvs {
  margin-top: var(--org-unit-s);
  margin-bottom: var(--org-unit-s);
}

/* Margin Medium */
.org-mam {
  margin: var(--org-unit-m);
}
.org-mtm {
  margin-top: var(--org-unit-m);
}
.org-mlm {
  margin-left: var(--org-unit-m);
}
.org-mrm {
  margin-right: var(--org-unit-m);
}
.org-mbm {
  margin-bottom: var(--org-unit-m);
}
.org-mhm {
  margin-left: var(--org-unit-m);
  margin-right: var(--org-unit-m);
}
.org-mvm {
  margin-top: var(--org-unit-m);
  margin-bottom: var(--org-unit-m);
}

/* Margin Large */
.org-mal {
  margin: var(--org-unit-l);
}
.org-mtl {
  margin-top: var(--org-unit-l);
}
.org-mll {
  margin-left: var(--org-unit-l);
}
.org-mrl {
  margin-right: var(--org-unit-l);
}
.org-mbl {
  margin-bottom: var(--org-unit-l);
}
.org-mhl {
  margin-left: var(--org-unit-l);
  margin-right: var(--org-unit-l);
}
.org-mvl {
  margin-top: var(--org-unit-l);
  margin-bottom: var(--org-unit-l);
}

/* Colors
---------------------------------------------------------------- */
.org-color-bright {
  color: var(--org-color-dark);
  background-color: var(--org-color-bright);
}
.org-color-shade {
  color: var(--org-color-dark);
  background-color: var(--org-color-shade);
}
.org-color-dark {
  color: var(--org-color-bright);
  background-color: var(--org-color-dark);
}
.org-color-error {
  color: var(--org-color-bright);
  background-color: var(--org-color-error);
}
.org-color-success {
  color: var(--org-color-bright);
  background-color: var(--org-color-success);
}
.org-color-primary {
  color: var(--org-color-bright);
  background-color: var(--org-color-primary);
}
.org-color-unsaved {
  color: var(--org-color-dark);
  background-color: var(--org-color-unsaved);
}
.org-color-warning {
  color: var(--org-color-dark);
  background-color: var(--org-color-warning);
}
.org-color-dark .org-btn--primary {
  box-shadow: none;
} /* Glow is not so nice on dark background */

/* Shadows
---------------------------------------------------------------- */
.org-shadow-s {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
.org-shadow-m {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.org-shadow-l {
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
}
