.toast-Container {
  padding: 10px;
}
.Toastify__toast-container {
  width: auto;
  max-width: 24.5rem;
}

.toast-title {
  font-weight: bold;
  font-size: 20px;
}

.Toastify__toast--success,
.Toastify__toast--error {
  border-radius: 8px !important;
}
.Toastify__toast--success {
  background-color: #1e88e5;
}
.Toastify__toast--info {
  background-color: #ffe082;
  border-radius: 10px !important;
}
.Toastify__toast--info .toast-Container > div {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #455a64 !important;
}

.custom-toast {
  background-color: #4caf50;
  border-radius: 10px !important;
}
.custom-toast .toast-Container > div {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff !important;
}

.Toastify__close-button {
  font-weight: 400 !important;
}
.Toastify__close-button--success,
.Toastify__close-button--error,
.Toastify__toast--info,
.custom-toast {
  margin-top: 10px;
  margin-right: 10px;
}
