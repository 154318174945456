.modal_overlay {
  min-width: 460px;
  width: 100vw;
  min-height: 700px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal_container {
  width: 340px;
  max-width: 80%;
  height: auto;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.modal_header {
  width: 100%;
  height: 58px;
  padding: 17.5px 21px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 18px;
  font-weight: 700;
}

.modal_content {
  width: 100%;
  min-height: 70px;
  font-size: 14px;
  padding: 17.5px 21px;
}

.modal_action-footer {
  width: 100%;
  height: 58px;
  padding: 17.5px 21px;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
